import React, { useState, useCallback } from 'react'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { debounce } from '../../helpers/utility';

const useStyles = makeStyles(theme => ({
  coverImg: {
    width: "100%"
  },
  header: {
    padding: 0,
    margin: '5px 0px'
  },
  red: {
    color: "red"
  },
  category: {
    margin: '5px 10px',
    color: '#3783e4',
  },
  divider: {
    margin: 15
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const SearchInput = ({ placeholder, onSearch }) => {
  const classes = useStyles();
  const [text, setText] = useState('');

  const debounceSearch = useCallback(debounce(onSearch, 500), []);

  function handleOnChange(e) {
    setText(e.target.value);
    debounceSearch(e.target.value);
  }

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        onChange={handleOnChange}
      />
      <IconButton onClick={() => debounceSearch(text)} className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default SearchInput;
