import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setSymptoms } from '../../actions/assessmentInfo';
import { getSymptoms } from '../../apis/ai';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import SearchInput from '../SearchInput';
import SkeletonLoader from '../SkeletonLoader';

const useStyles = makeStyles((theme) => ({
  stickyButton: {
    position: 'sticky',
    bottom: '10px',
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  chipList: {
    minHeight: theme.spacing(3),
    margin: '4px 0',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const sortById = (a, b) => {
  return a.id - b.id;
}

const filteredSymptom = (search, symptoms) => {
  var _symptoms = symptoms.map(val => val['name_th']);
  var res = search ? _symptoms.filter(val => val.includes(search)) : _symptoms;

  var arry = ['ท้อง','หัว','หลัง','คอ','น่อง','ข้อ','ไข้','เจ็บ','เสมหะ','น้ำมูก','หน้า','จมูก','ใจ'];
  for (var i = 0; i < arry.length; i++) {
    var temp = arry[i];
    if (search.includes(temp)) {
      res = res.concat(_symptoms.filter(val => val.includes(temp)));
    }
  }
  return symptoms.filter(val => val['name_th'] && res.includes(val['name_th']));
}

const SymptomsSelector = ({ nextStep }, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [symptoms, setQuestions] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const assessmentInfo = useSelector(state => state.assessmentInfo);

  React.useEffect(() => {
    loadSymptoms();
  }, []);

  const loadSymptoms = async () => {
    try {
      const res = await getSymptoms();
      setQuestions(res.sort(sortById));
    } catch (e) {
    }
    setLoading(false);
  }

  const handleClick = (id) => () => {
    dispatch(setSymptoms({
      [id]: assessmentInfo.symptoms[id] ? 0 : 1
    }));
  }

  const _symptoms = symptoms || []

  const filteredSymptoms = filteredSymptom(searchText, _symptoms);
  const filteredSelectedSymptoms = _symptoms.filter(val => !!assessmentInfo.symptoms[val.id])

  return (
    <Box maxWidth={800} width="100%" ml="auto" mr="auto">
      <h2>คุณมีอาการอย่างไร?</h2>
      <SearchInput placeholder="ค้นหาอาการ" onSearch={setSearchText} />
      <div className={classes.chipList}>
        {
          filteredSelectedSymptoms.map((data) => {
            const { id, name_th } = data;
            return (
              <Chip key={id} variant="outlined" size="small" label={name_th} onDelete={handleClick(id)} />
            );
          })
        }
      </div>
      <h3>อาการที่พบบ่อย <small>(เลือกได้ 3 อาการ)</small></h3>
      <Box flex={1} overflow="scroll">
        {
          loading ? <SkeletonLoader noAvatar /> : (
            <div className={classes.chipList}>
              {
                searchText && !filteredSymptoms.length && <p>ไม่พบรายการ</p>
              }
              {
                filteredSymptoms.map((data) => {
                  const { id, name_th } = data;
                  return (
                    <Chip
                      key={id}
                      disableRipple
                      label={name_th}
                      variant={assessmentInfo.symptoms[id] ? "default" : "outlined"}
                      color={assessmentInfo.symptoms[id] ? "primary" : "default"}
                      // icon={assessmentInfo.symptoms[id] ? <DoneIcon /> : null}
                      onClick={handleClick(id)}
                    />
                  );
                })
              }
            </div>
          )
        }
        <div className={classes.stickyButton}>
          {
            !!filteredSelectedSymptoms.length && (
              <Box mt={8}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={nextStep}
                >
                  ต่อไป
                </Button>
              </Box>
            )
          }
        </div>
      </Box>
    </Box>
  )
}

export default React.forwardRef(SymptomsSelector);
