import { PromotionType, NUMBER_OF_POINT_REDEMPTION } from './constants';
import { navigate } from 'gatsby';

export const debounce = (func, delay) => {
  let inDebounce;
  return function() {
    const context = this;
    const args = arguments;
    const e = args[0];
    if (e.persist) {
      // See https://reactjs.org/docs/legacy-event-pooling.html
      e.persist();
    }
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func && func.apply(context, args), delay);
  };
};

export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function() {
    const context = this;
    const args = arguments;
    const e = args[0];
    if (e.persist) {
      // See https://reactjs.org/docs/legacy-event-pooling.html
      e.persist();
    }
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function() {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export function calculateByItem(item) {
  return (
    parseFloat(item.item_price) * item.quantity
  )
};

export function subtotal(items) {
  if (!Array.isArray(items)) return 0;
  return (
    items
      .map(({ item_price, quantity }) => parseFloat(item_price) * quantity)
      .reduce((sum, i) => sum + i, 0) || 0
  );
}

export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function navigateTo(link) {
  if (!link) {
    return;
  }
  if (link.indexOf('http') != -1) {
    window.location = link;
  } else {
    navigate(link);
  }
}

export const getMessageBody = message => {
  let msg;
  try {
    msg = JSON.parse(message.body);
  } catch (e) {
    msg = { type: 'text', value: message.body };
  }
  return msg;
};

const getQuestionAnswerMessages = answers => {
  return answers.map((answer, index) => ({
    type: 'text',
    contents: [
      {
        type: 'span',
        text: `${index + 1}. ${answer.question} - `,
      },
      {
        type: 'span',
        text: answer.answer.replace(/[\[|\]|\']/gi, '') || 'NA',
        color: '#008000',
      },
    ],
    wrap: true,
  }));
};

const getInterpretMessage = interprets => {
  return interprets.map(interpret => interpret.interpret).join(', ');
};

export function resultMessage(data) {
  const COUNT_URL = 'https://liff.line.me/1654027181-YknkXb8Q';
  return {
    type: 'bubble',
    body: {
      type: 'box',
      layout: 'vertical',
      contents: [
        {
          type: 'text',
          text: 'ขอบคุณที่ทำแบบประเมิน',
          weight: 'bold',
          size: 'xl',
          color: '#008000',
        },
        {
          type: 'text',
          text: 'Mor Online',
          margin: 'md',
          size: 'xl',
          weight: 'bold',
        },
        {
          type: 'text',
          text: `Id #${data.user_answers.id}`,
          color: '#808080',
          margin: 'md',
        },
        {
          type: 'text',
          text: data.user_answers.questionnaire.name,
          margin: 'md',
          wrap: true,
        },
        {
          type: 'text',
          text: `ผู้ประเมิน - ${data.user_relation.fullname} (${data.user_relation.relation_name})`,
          margin: 'md',
          wrap: true,
        },
        {
          type: 'text',
          text: 'คำถาม',
          contents: [],
          size: 'lg',
          weight: 'bold',
          margin: 'md',
        },
        ...getQuestionAnswerMessages(data.user_answers.answers),
        {
          type: 'text',
          text: 'ผลการประเมิน',
          margin: 'md',
          color: '#0012f7',
          size: 'lg',
          weight: 'bold',
        },
        {
          type: 'text',
          text: getInterpretMessage(data.interprets),
          margin: 'sm',
          size: 'md',
          wrap: true,
        },
      ],
    },
    footer: {
      type: 'box',
      layout: 'vertical',
      spacing: 'sm',
      contents: [
        {
          type: 'separator',
          margin: 'lg',
        },
        {
          type: 'button',
          action: {
            type: 'uri',
            label: 'ต้องการปรึกษาผู้เชี่ยวชาญ',
            uri: `https://liff.line.me/${process.env.GATSBY_LIFF_APPOINTMENT_ID}/consultants/?category_id=${data.user_answers.questionnaire.category}&user_question_id=${data.user_answers.id}&liffId=${process.env.GATSBY_LIFF_APPOINTMENT_ID}&useLiff=true`,
          },
          style: 'primary',
          margin: 'lg',
        },
        {
          type: 'button',
          action: {
            type: 'uri',
            label: 'ค้นหา รพ./ศูนย์ให้บริการใกล้ฉัน',
            uri: `${COUNT_URL}?code=hospital`,
          },
          style: 'primary',
          color: '#3A81EC',
        },
        {
          type: 'button',
          action: {
            type: 'uri',
            label: 'ค้นหาบริการ/สินค้า ที่เหมาะกับฉัน',
            uri: `${COUNT_URL}?code=product`,
          },
          style: 'primary',
          color: '#17B9A3',
        },
        {
          type: 'text',
          text:
            'แบบประเมินนี้ ไม่ได้เป็นการวินิจฉัยใดๆทั้งสิ้น เป็นเพียงการคัดกรองประเมินเบื้องต้นเท่านั้น',
          wrap: true,
          align: 'center',
          size: 'md',
          color: '#ff0000',
          margin: 'lg',
        },
      ],
      flex: 0,
    },
  };
}
