import React from 'react';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import { postEvaluation } from '../../actions/assessmentInfo';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import LinearProgressWithLabel from '../../components/LinearProgressWithLabel';
import Layout from '../../components/Layout';
import SymptomsSelector from '../../components/Assessment/SymptomsSelector';

export default () => {
  const dispatch = useDispatch();
  const formRef = React.useRef(null);

  const nextStep = async () => {
    try {
      await dispatch(postEvaluation());
      navigate('/assessment/symptomLoop');
    } catch (e) {
    }
  }

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>แบบประเมินสุขภาพ - MorOnline</title>
      </Helmet>
      <Box p={2} pb={0} height="100%" overflow="hidden" display="flex" flexDirection="column">
        <LinearProgressWithLabel value={1} />
        <SymptomsSelector ref={formRef} nextStep={nextStep}/>
      </Box> 
    </Layout>
  );
}

