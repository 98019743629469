import * as Constant from './constants';
import * as Api from '../apis';

export const setPersonalInfo = payload => ({
  type: Constant.SET_ASSESSMENT_PERSONAL_INFO,
  payload,
});

export const setVitalSign = payload => ({
  type: Constant.SET_ASSESSMENT_VITAL_SIGN,
  payload,
});

export const setSymptoms = payload => ({
  type: Constant.SET_ASSESSMENT_SYMPTOMS,
  payload,
});

export const setSkipAi = payload => ({
  type: Constant.SET_ASSESSMENT_SKIP_AI,
  payload,
});

export const postEvaluation = () => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.ASSESSMENT_EVALUATION_POST_REQUEST });
    let cardID = null;
    let partnerId = null;
    const { partners } = getState();
    if (!!partners) {
      const { cardId } = partners;
      partnerId = partners.partnerId;
      cardID = cardId;
    }
    return Api.postEvaluation(cardID, partnerId, !!partnerId)
      .then(payload => {
        dispatch({
          type: Constant.ASSESSMENT_EVALUATION_POST_SUCCESS,
          payload,
        });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.ASSESSMENT_EVALUATION_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const postPatientRecord = payload => {
  return dispatch => {
    dispatch({ type: Constant.ASSESSMENT_PATIENT_POST_REQUEST });

    // Object.keys(payload).forEach(key => {
    //   if (!Boolean(payload[key])) {
    //     delete payload[key];
    //   }
    // });

    return Api.postPatientRecord(payload)
      .then(payload => {
        dispatch({ type: Constant.ASSESSMENT_PATIENT_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.ASSESSMENT_PATIENT_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const getPatientRecord = () => {
  return dispatch => {
    dispatch({ type: Constant.ASSESSMENT_PATIENT_GET_REQUEST });
    return Api.getPatientRecord()
      .then(payload => {
        dispatch({ type: Constant.ASSESSMENT_PATIENT_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.ASSESSMENT_PATIENT_GET_FAILED, message });
      });
  };
};

export const getLatestAssessment = () => {
  return async dispatch => {
    dispatch({ type: Constant.ASSESSMENT_PATIENT_LATEST_GET_REQUEST });
    try {
      let res, payload;
      res = await Api.getPatientList();
      const item = res[0];
      if (item && item.assessment_datetimes[0]) {
        payload = await Api.getPatientRecordAssessment(
          item.id,
          item.assessment_datetimes[0],
        );
      } else {
        payload = await Api.getPatientRecordAssessment('None', 'None');
      }
      dispatch({
        type: Constant.ASSESSMENT_PATIENT_LATEST_GET_SUCCESS,
        payload,
      });
      return payload;
    } catch (message) {
      dispatch({
        type: Constant.ASSESSMENT_PATIENT_LATEST_GET_FAILED,
        message,
      });
    }
  };
};
